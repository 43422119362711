@import 'styles/variables.scss';

html {
  scroll-padding-top: 160px;
}

@media only screen and (min-width: 576px) {
  html {
    scroll-padding-top: 103px;
  }
}

@media only screen and (min-width: 992px) {
  html {
    scroll-padding-top: 153px;
  }
}

@media only screen and (min-width: 1200px) {
  html {
    scroll-padding-top: 153px;
  }
}

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role='list'],
ol[role='list'] {
  list-style: none;
  padding: 0;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  min-height: 100vh;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  font-family: $font-family-default;
  color: $text-color-primary;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
}

sup {
  font-size: 65%;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.nexplanon-terms-and-conditions .ant-modal-body {
  .ddc-tac-close-modal {
    opacity: 0;
  }

  &::before {
    content: '';
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    right: 24px;
    top: 24px;
    pointer-events: none;
    background: #f0ab99 url('/assets/images/brand-icons/icon-close.webp')
      no-repeat center center;
  }
}

body.overflowHidden {
  overflow: hidden;
}

body.hideChatbox [class*='webchatv'] {
  display: none !important;
}
